<template>
  <div class="side-padding" style="padding-top: 10vh; text-align: center;">
    <p>Tippelaget.no er på pause. Vi får se om vi kommer tilbake.</p>
  </div>
  <!-- <div class="intro-wrapper">
    <form class="intro-form" @submit.prevent="connect()">
      <h1>Koble til tippelag</h1>
      <div class="form-row">
        <label class="label" for="team-name">Tippelagskode</label>
        <input class="input" id="team-name" type="text" v-model="teamCode" autocomplete="off" />
      </div>
      <p v-if="!isTyping && formErrorMessage" class="alert">
        {{ formErrorMessage }}
      </p>
      <div class="btn-wrapper">
        <button class="btn" type="submit">Koble til tippelag</button>
      </div>
    </form>

    <p class="u-text-center">
      - Eller -
      <br />
      <router-link to="/team-create">Opprett nytt tippelag</router-link>
    </p>
    <Spinner v-if="isLoading" />
  </div> -->
</template>

<!-- <script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue"
import { sleep } from "../../../../shared/utils/sleep"
import { TippelagetErrorMessages } from "../../i18n/tippelagetErrorMessages"
import userStore from "../../store/userStore"
import teamStore from "../../store/teamStore"
import router from "../../router"

export default defineComponent({
  props: {
    teamId: {
      type: String,
    },
  },
  setup(props) {
    const teamCode = ref("")
    const isTyping = ref(false)
    const isLoading = ref(false)
    const formErrorMessage = ref("")
    const { user, addTeamToUser } = userStore()
    const { getTeam, saveTeam } = teamStore()

    watch(teamCode, () => {
      if (!teamCode.value) return

      isTyping.value = true
      formErrorMessage.value = ""
      sleep(1000).then(() => {
        isTyping.value = false
        validateForm()
      })
    })

    onMounted(() => {
      if (props.teamId) teamCode.value = props.teamId
    })

    const teamCodeTrimmed = computed(() => teamCode.value.trim())

    const validateForm = () => {
      if (teamCodeTrimmed.value && teamCodeTrimmed.value.length > 6) return
      formErrorMessage.value = TippelagetErrorMessages.teamCodeInvalid
    }

    const connect = async () => {
      validateForm()

      if (formErrorMessage.value || isLoading.value) return

      isLoading.value = true

      const team = await getTeam(teamCodeTrimmed.value)

      try {
        if (!team || !team.memberIds) {
          throw new Error("connectTeam error, team null ref")
        }

        if (team.memberIds.includes(user.value.id)) {
          throw "alreadyMember"
        }

        team.memberIds.push(user.value.id)

        await saveTeam(team)
        await addTeamToUser(user.value, team.id)

        teamCode.value = ""
        router.push("/profile")
      } catch (error) {
        if (error === "alreadyMember") {
          formErrorMessage.value = "Allerede medlem"
        } else {
          console.debug(error)
          formErrorMessage.value = TippelagetErrorMessages.teamCodeInvalid
        }
      }
      isLoading.value = false
    }

    return { teamCode, isLoading, isTyping, formErrorMessage, connect }
  },
})
</script> -->

<style lang="scss" scoped>
@import "./src/SASS/settings";
@import "./src/SASS/modules/intro";
</style>
